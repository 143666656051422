.Toastify__toast-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
}
.Toastify__toast-container--top-center, .Toastify__toast-container--bottom-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}